























import { doGetUserMessage } from '@/api/message/index'
import dayjs from 'dayjs';
export default {
  data() {
    return {
      messageCenterData: [] as any[],
      pagination: {
        currentPage: 1,
        total: 0,
        pageSize: 10
      },
    }
  },
  methods: {
    handleSizeChange(pageSize: number) {
      this.pagination.currentPage = 1;
      this.pagination.pageSize = pageSize;
      this.initalData()
    },
    handleCurrentChange(currentPage: number) {
      this.pagination.currentPage = currentPage;
      this.initalData()
    },
    async initalData() {
      let datas = [], total = 0;
      const userInfo = this.$store.state.user.userInfo || {}
      try {
        const result = await doGetUserMessage({ current: this.pagination.currentPage, size: this.pagination.pageSize, userId: userInfo.userId });
        if (result.data) {
          datas = result.data.records.map((item: any) => ({...item, createTime: item.createTime ? dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss') : ''}));
          total = result.data.total;
        }
      } finally {
        this.messageCenterData = datas;
        this.pagination.total = total;
      }
    }
  },
  mounted() {
    this.initalData()
  },
}
